import React, {MetaHTMLAttributes} from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'

interface Props {
  readonly title?: string
  readonly description?: string
  readonly lang?: string
  readonly meta?: Array<MetaHTMLAttributes<HTMLMetaElement>>
}

const Head: React.FC<Props> = ({description, lang, meta, title}) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  const metaDescription = description || (site.siteMetadata.description as string)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author as string,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta as [])}
    />
  )
}

Head.defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.any),
}

export default Head
